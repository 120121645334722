import React, { FC, useCallback, useState } from 'react'
import { blogPostsByCategoryPath, requestMoreBlogPosts } from 'pages/_serverRendered/Blog/utils'

import PageLoad from 'non-rendering/PageLoad'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'
import Button from 'components/Button2'
import cx from 'classnames'
import Footer from 'components/MarketingSite/Footer'
import GetStarted from 'pages/_serverRendered/Homepage/GetStarted'
import Header from 'components/MarketingSite/Header'
import HighlightedBlogPost from 'pages/_serverRendered/Blog/Home/HighlightedBlogPost'
import { PrismicBlogPostResult, PrismicBlogCategoryResult } from 'pages/_serverRendered/Blog/types'
import StandardBlogPost from 'pages/_serverRendered/Blog/Home/StandardBlogPost'

import styles from '../styles.module.scss'

interface HomeProps {
  categories: PrismicBlogCategoryResult[]
  active_category: PrismicBlogCategoryResult | null
  highlighted_result: PrismicBlogPostResult | null
  results: PrismicBlogPostResult[]
  has_more: boolean
  params: Record<string, string>
  blog_home_data: BlogHomeData
}

const Home: FC<HomeProps> = ({
  categories,
  active_category,
  highlighted_result,
  results,
  params,
  has_more,
  blog_home_data,
}) => {
  const [hasMore, setHasMore] = useState<boolean>(has_more)
  const [blogPosts, setBlogPosts] = useState<PrismicBlogPostResult[]>(results)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)

  const handleLoadMore = useCallback(async () => {
    const nextPage = page + 1
    setPage(nextPage)
    setIsLoading(true)
    try {
      const json = await requestMoreBlogPosts({ ...params, page: String(nextPage) })
      if (json) {
        setBlogPosts([...blogPosts, ...json.results])
        setHasMore(json.has_more)
      }
    } catch (e) {
      const win = window as any
      win.Sentry ? win.Sentry.captureException(e) : console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [blogPosts, page, params, setPage])

  return (
    <div>
      <PageLoad name="resources-home" />
      <Header />
      <div className="tw-flex tw-flex-col tw-items-center tw-p-5 tw-mb-20">
        <div className="tw-max-w-6xl tw-space-y-8">
          {highlighted_result && <HighlightedBlogPost blogPost={highlighted_result} />}
          {categories.length > 0 && (
            <section>
              <div className="tw-text-center">
                {categories.map((category) => (
                  <a
                    key={category.id}
                    href={blogPostsByCategoryPath(category.uid)}
                    className={cx('tw-inline-block tw-uppercase tw-my-3 tw-mx-6 hover:tw-text-moss', {
                      'tw-underline': active_category?.uid === category.uid,
                    })}
                  >
                    {category.data.display_name}
                  </a>
                ))}
              </div>
            </section>
          )}
          <section>
            <div className="tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 md:tw-gap-8">
              {blogPosts.map((blogPost) => (
                <StandardBlogPost blogPost={blogPost} key={blogPost.id} />
              ))}
              {blogPosts.length === 0 && 'No blog posts found.'}
            </div>
          </section>
          {hasMore && (
            <Button onClick={handleLoadMore} loading={isLoading}>
              {'Load more'}
            </Button>
          )}
        </div>
      </div>
      <GetStarted className={styles.getStarted} id="getstarted" title="Get started" />
      <Footer blog_home_data={blog_home_data} />
    </div>
  )
}

export default Home
