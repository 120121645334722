import React, { FC, useCallback } from 'react'
import { RecoilRoot, useRecoilValue } from 'recoil'
import type { MutableSnapshot } from 'recoil'

import {
  BuildableArea,
  publicPropertyAvmState,
  PublicPropertyPotential,
  publicPropertyPotentialState,
  publicPropertyState,
  publicPropertyBuildableAreaState,
  publicPropertyEnrichedState,
  PublicPropertyEnriched,
  publicPropertyPrismicDataState,
  PublicPropertyPrismicData,
  publicPropertyRelativeStatsState,
  PublicPropertyLightRelativeStats,
  publicPropertyIndexedState,
} from 'recoil/publicProperty'
import { formatPublicPropertyAddress } from 'utils/formatting'
import { signUpPathWithPublicProperty } from 'utils/publicProperty'
import { trackSync as track } from 'utils/analytics'

import PageLoad from 'non-rendering/PageLoad'

import Header from 'components/MarketingSite/Header'
import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'

import ComparableHomesNearby from './ComparableHomesNearby'
import CurrentValue from './CurrentValue'
import Footer from './Footer'
import FAQ from './FAQ'
import Hero from './Hero'
import ProjectCustomization from './ProjectCustomization'
import SaveThisProperty from './SaveThisProperty'
import ScoreAndLivableSpace from './ScoreAndLivableSpace'
import Quotes from './Quotes'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'

import styles from './styles.module.scss'
import InternalLinks, { InternalLink } from 'components/InternalLinks'
import cx from 'classnames'

interface PublicPropertyProps {
  blog_home_data: BlogHomeData
  internal_links?: InternalLink[]
}

const PublicProperty: FC<PublicPropertyProps> = ({ blog_home_data, internal_links }) => {
  const publicProperty = useRecoilValue(publicPropertyState)
  const publicPropertyPrismicData = useRecoilValue(publicPropertyPrismicDataState)

  const handleGetStartedClick = useCallback(() => {
    track('getStarted')
  }, [])

  if (!publicProperty || !publicPropertyPrismicData) return null

  return (
    <div className={styles.publicProperty}>
      <PageLoad name={`public-property`} />
      <Header taxAssessor={publicProperty}>
        <AddressAutocomplete
          placeholder={['Enter an address to view another property', 'Enter an address']}
          positionLabel="header"
        />
      </Header>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.sidebar}>
            <h1 className={styles.mobileTitle}>{formatPublicPropertyAddress(publicProperty)}</h1>
            <div>
              <h2>{publicPropertyPrismicData.title?.[0]?.text}</h2>
              {publicPropertyPrismicData.description?.map((e, i) => <p key={i}>{e.text}</p>)}
            </div>
          </div>
          <div className={styles.primaryColumn}>
            <Hero />
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.sidebar}>
            <div className={styles.ctaContainer} data-hide-on="logged-in">
              <h3>{'Start building a plan for your property with a free account'}</h3>
              <a
                id="get-started-cta"
                className={styles.ctaButton}
                href={signUpPathWithPublicProperty(publicProperty)}
                onClick={handleGetStartedClick}
              >
                {'Get started' /* note this is using a no-break space */}
              </a>
            </div>
          </div>
          <div className={styles.primaryColumn}>
            <ProjectCustomization />
            <ScoreAndLivableSpace />
            <CurrentValue />
            <ComparableHomesNearby />
            <Quotes />
          </div>
        </div>
        <SaveThisProperty />
        <FAQ />
        {internal_links && internal_links.length > 0 && (
          <div className={cx(styles.container, styles.internalLinks)}>
            <h3>{'Explore more on Realm'}</h3>
            <InternalLinks links={internal_links} />
          </div>
        )}
        <Footer blog_home_data={blog_home_data} />
      </div>
    </div>
  )
}

export interface PublicPropertyRecoilContainerProps {
  avm: AttomAvm
  buildable_area: BuildableArea
  potential: PublicPropertyPotential
  prismic_data: { data: PublicPropertyPrismicData }
  public_property: AttomTaxAssessor
  enriched: PublicPropertyEnriched
  light_relative_stats: PublicPropertyLightRelativeStats | null
  blog_home_data: BlogHomeData
  indexed: boolean
  internal_links?: InternalLink[]
}

// Container that just sets the initial recoil state
// I know, I know...one component per file, but this is just a little baby wrapper for Recoil :)
const PublicPropertyRecoilContainer: React.FC<PublicPropertyRecoilContainerProps> = ({
  avm,
  buildable_area,
  potential,
  prismic_data,
  public_property,
  enriched,
  light_relative_stats,
  blog_home_data,
  indexed,
  internal_links,
}) => {
  const initializeState = useCallback(
    ({ set }: MutableSnapshot): void => {
      set(publicPropertyAvmState, avm)
      set(publicPropertyState, public_property)
      set(publicPropertyPotentialState, potential)
      set(publicPropertyPrismicDataState, prismic_data.data)
      set(publicPropertyBuildableAreaState, buildable_area)
      set(publicPropertyEnrichedState, enriched)
      set(publicPropertyRelativeStatsState, light_relative_stats)
      set(publicPropertyIndexedState, indexed)
    },
    [avm, buildable_area, potential, prismic_data, public_property, enriched, light_relative_stats, indexed]
  )

  return (
    <RecoilRoot initializeState={initializeState}>
      <PublicProperty blog_home_data={blog_home_data} internal_links={internal_links} />
    </RecoilRoot>
  )
}

export default PublicPropertyRecoilContainer
