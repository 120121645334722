import React, { FC } from 'react'
import { FooterContainer, Hr, SocialLinks } from 'components/MarketingAndPropertyPages/Footer'
import BrowseStates from 'components/MarketingAndPropertyPages/Footer/BrowseStates'
import HomepageLinks from 'components/MarketingAndPropertyPages/Footer/HomepageLinks'
import Breadcrumb from 'components/MarketingAndPropertyPages/Footer/Breadcrumb'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'
import BlogLinks from 'components/MarketingSite/Footer/BlogLinks'

interface FooterProps {
  blog_home_data?: BlogHomeData
}

const Footer: FC<FooterProps> = ({ blog_home_data }) => (
  <FooterContainer>
    <Hr />
    <BrowseStates />
    <Breadcrumb />
    <Hr />
    {blog_home_data && (
      <>
        <BlogLinks blog_home_data={blog_home_data} />
        <Hr />
      </>
    )}
    <HomepageLinks />
    <SocialLinks />
  </FooterContainer>
)

export default Footer
