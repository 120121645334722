import React, { useState, useCallback, useEffect, FC } from 'react'
import classNames from 'classnames'

import SparkleImg from 'svgs/property-plan/sparkles'

import Card from 'components/Card'

import Header from 'components/MarketingSite/Header'
import Footer from 'components/MarketingSite/Footer'
import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import YellowBubbleCta from 'components/MarketingAndPropertyPages/YellowBubbleCta'
import { formatPublicPropertyAddress } from 'utils/formatting'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'

import styles from './styles.module.scss'

interface UnavailableProps {
  address: string
  attom_id?: string
  suggestions?: {
    attom_id: string
    propertyaddressfull: string
    propertyaddresscity: string
    propertyaddressstate: string
    propertyaddresszip: string
    public_property_path: string
  }[]
  blog_home_data: BlogHomeData
}

const Unavailable: FC<UnavailableProps> = ({ address, attom_id, suggestions, blog_home_data }) => {
  const [email, setEmail] = useState('')
  const [completed, setCompleted] = useState(false)

  const [campaignId, setCampaignId] = useState<string>()

  useEffect(() => {
    if (!campaignId) {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.has('mc')) {
        // If this was a marketing campaign, grab the campaign
        const newCampaignId = searchParams.get('mc') as string
        setCampaignId(newCampaignId)
      }
    }
  }, [campaignId])

  const handleChangeEmail = useCallback((e) => {
    setEmail(e.target.value)
  }, [])
  const handleFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      await fetch('#', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
        body: JSON.stringify({
          address: address,
          attom_id: attom_id,
          email: email,
        }),
      } as any)
      setCompleted(true)
    },
    [address, attom_id, email]
  )

  return (
    <div className={styles.unavailable}>
      <Header campaignId={campaignId}>
        <AddressAutocomplete
          placeholder={['Enter an address to view another property', 'Enter an address']}
          positionLabel="header"
        />
      </Header>
      <div className={styles.wrapper}>
        <Card className={styles.card}>
          <h1>{address}</h1>
          <h2>{'This property is not covered.... yet!'}</h2>
          {(suggestions?.length || 0) > 0 && (
            <section className={styles.didYouMeanSection}>
              <h3>{'Did you mean?'}</h3>
              <div>
                {suggestions?.map((suggestion) => {
                  let linkPath = `/onboarding?attom_id=${suggestion.attom_id}`
                  // If they're here as part of a marketing campaign, we need to send them
                  // to sign_up with these links.
                  if (campaignId) {
                    linkPath = `/users/sign_up?attom_id=${suggestion.attom_id}&mc=${campaignId}`
                  }
                  return (
                    <div className={styles.suggestion} key={suggestion.attom_id}>
                      <a href={linkPath}>{formatPublicPropertyAddress(suggestion as any)}</a>
                    </div>
                  )
                })}
              </div>
            </section>
          )}
          <p>
            {'Realm offers coverage of detached single family homes in '}
            <a
              href="/resources/library/realm-now-available-for-80m-single-family-homes/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {'certain geographies'}
            </a>
            {
              '. We are actively adding new geographies each week and plan to expand to multi-family homes and condos later this year.'
            }
          </p>
          {/* Potential coverage map */}
          {!completed ? (
            <form method="post" onSubmit={handleFormSubmit}>
              <div className={styles.formContent}>
                <p>{'Sign up to be notified once we are live in your neighborhood.'}</p>
                <label htmlFor="tbxEmail">{'Email'}</label>
                <input
                  className="emailFieldExtraCSS"
                  type="email"
                  id="tbxEmail"
                  name="email"
                  placeholder="your.email@provider.com"
                  required
                  onChange={handleChangeEmail}
                  value={email}
                />

                <YellowBubbleCta type="submit" className={classNames(styles.submit, styles.big, 'notifyExtraCSS')}>
                  {'Notify me when this address is covered'}
                </YellowBubbleCta>
                <YellowBubbleCta type="submit" className={classNames(styles.submit, styles.small, 'notifyExtraCSS')}>
                  {'Notify me'}
                </YellowBubbleCta>
              </div>
            </form>
          ) : (
            <div>
              <div className={styles.success}>
                <SparkleImg className={styles.sparkles} />

                <h2>{'Thank you for your interest'}</h2>
                <p>
                  {'We will email you at'}
                  <span className={styles.email}>{email}</span>
                  {'when we have coverage in your area!'}
                </p>
                <p>{'In the meantime, try searching for another property.'}</p>
              </div>
            </div>
          )}
        </Card>
        <Footer blog_home_data={blog_home_data} />
      </div>
    </div>
  )
}

export default Unavailable
