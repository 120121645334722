import StyledRichText from 'components/MarketingAndPropertyPages/StyledRichText'
import Footer from 'components/MarketingSite/Footer'
import Header from 'components/MarketingSite/Header'
import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import { RichTextBlock } from 'prismic-reactjs'
import React, { FC } from 'react'
import Section from 'pages/_serverRendered/Public/shared/Section'
import Statistics from 'pages/_serverRendered/Public/shared/Statistics'
import FindPropertyCTA from 'pages/_serverRendered/PublicNeighborhood/FindPropertyCTA'
import GetRealmScoreCTA from 'pages/_serverRendered/PublicNeighborhood/GetRealmScoreCTA'
import Projects from 'pages/_serverRendered/Public/shared/Projects'
import IntroCopy from 'pages/_serverRendered/Public/shared/IntroCopy'
import styles from './styles.module.scss'
import PopularCities from 'pages/_serverRendered/Public/shared/PopularCities'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'
import { AggregateStats, PopularCity, TopProject } from 'recoil/publicNeighborhood'
import HomeSearchListings, { HomeSearchResultItem } from 'pages/_serverRendered/HomeSearch/HomeSearchListings'
import InternalLinks, { InternalLink } from 'components/InternalLinks'
import PageLoad from 'non-rendering/PageLoad'

interface CityProps {
  state_code: string
  state_name: string
  city: string
  blog_home_data: BlogHomeData
  city_description: RichTextBlock[]
  popular_cities: PopularCity[]
  top_projects: TopProject[]
  stats: AggregateStats
  listings: HomeSearchResultItem[]
  internal_links?: InternalLink[]
}

const City: FC<CityProps> = ({
  city,
  city_description,
  popular_cities,
  state_code,
  state_name,
  top_projects,
  blog_home_data,
  stats,
  listings,
  internal_links,
}) => {
  return (
    <div>
      <PageLoad name={`public-city-${state_code}-${city.replace(' ', '-')}`} />
      <Header>
        <AddressAutocomplete
          placeholder={['Enter an address to view a property', 'Enter an address']}
          positionLabel="header"
        />
      </Header>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <section>
            <IntroCopy />
          </section>
          <hr />
          <section>
            <h1>
              {city}
              {' Real Estate & Home Prices'}
            </h1>
            <HomeSearchListings results={listings} columns={4} loading={false} />
          </section>
          <section>
            <div className={styles.breadcrumb}>
              <a href="/">{'Realm'}</a>
              <span className={styles.separator}>{'→'}</span>
              <a href={`/${state_code.toLowerCase()}`}>{state_name}</a>
              <span className={styles.separator}>{'→'}</span>
              <strong>{city}</strong>
            </div>
          </section>
          <Section>
            <Statistics name={city} stats={stats} />
          </Section>
          <section className={styles.popularProjects}>
            <h2>{`Popular Projects in ${city}`}</h2>
            <p className={styles.sectionDescription}>
              {
                'Our pricing estimates use local labor & material costs. With your free Realm account, you can customize pricing based on square footage and quality of materials.'
              }
            </p>
            <Projects projects={top_projects} />
          </section>
          <FindPropertyCTA className={styles.findPropertyCta} />
          {city_description != null && (
            <Section>
              <h2>{city}</h2>
              <StyledRichText richText={city_description} />
            </Section>
          )}
          <Section className={styles.transparent}>
            <PopularCities cities={popular_cities} stateCode={state_code} title={`Popular Cities Near ${city}`} />
          </Section>
          {internal_links && internal_links.length > 0 && (
            <Section className={styles.transparent}>
              <h2>{'Explore more on Realm'}</h2>
              <InternalLinks links={internal_links} />
            </Section>
          )}
        </div>
        <GetRealmScoreCTA />
        <Footer blog_home_data={blog_home_data} />
      </div>
    </div>
  )
}

export default City
