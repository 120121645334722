import React, { FC, useCallback } from 'react'
import { RecoilRoot } from 'recoil'
import type { MutableSnapshot } from 'recoil'

import { PublicNeighborhood, publicNeighborhoodState } from 'recoil/publicNeighborhood'

import PageLoad from 'non-rendering/PageLoad'

import Header from 'components/MarketingSite/Header'
import Footer from 'components/MarketingSite/Footer'
import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'

import Intro from './Intro'
import Menu from './Menu'
import Overview from './Overview'
import Statistics from './Statistics'
import FindPropertyCTA from './FindPropertyCTA'
import TopProjects from './TopProjects'
import TopScoringHomes from './TopScoringHomes'
import GetRealmScoreCTA from './GetRealmScoreCTA'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'

import styles from './styles.module.scss'

interface PublicNeighborhoodProps {
  blog_home_data: BlogHomeData
}

const PublicNeighborhood: FC<PublicNeighborhoodProps> = ({ blog_home_data }) => (
  <div className={styles.publicNeighborhood}>
    <Header>
      <AddressAutocomplete
        placeholder={['Enter an address to view a property', 'Enter an address']}
        positionLabel="header"
      />
    </Header>
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <Intro />
          <Menu />
        </div>
        <div className={styles.primaryColumn}>
          <Overview />
          <Statistics />
          <FindPropertyCTA />
          <TopProjects />
          <TopScoringHomes />
        </div>
      </div>
      <GetRealmScoreCTA />
      <Footer blog_home_data={blog_home_data} />
    </div>
  </div>
)

export interface PublicNeighborhoodRecoilContainerProps {
  public_neighborhood: PublicNeighborhood
  blog_home_data: BlogHomeData
}

// Container that just sets the initial recoil state
// I know, I know...one component per file, but this is just a little baby wrapper for Recoil :)
const PublicNeighborhoodRecoilContainer: FC<PublicNeighborhoodRecoilContainerProps> = ({
  public_neighborhood,
  blog_home_data,
}) => {
  const initializeState = useCallback(
    ({ set }: MutableSnapshot): void => {
      set(publicNeighborhoodState, public_neighborhood)
    },
    [public_neighborhood]
  )

  return (
    <RecoilRoot initializeState={initializeState}>
      <PageLoad
        name={`public-neighborhood-${public_neighborhood.state}-${public_neighborhood.name.replace(' ', '-')}`}
      />
      <PublicNeighborhood blog_home_data={blog_home_data} />
    </RecoilRoot>
  )
}

export default PublicNeighborhoodRecoilContainer
